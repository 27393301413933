<template>
  <div class="body">
    <div style="margin-bottom: 27px">
      <list-filter v-model="filterForm" :uploadFilter="ok" />
    </div>
    <list-tabs v-model="filterForm.tab" :isLoading="loading" :tabs="tabArr" @tab-click="handleClick">
    </list-tabs>
    <div class="list-content">
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%" v-loading="loading"
        @sort-change="sortChange">
        <!-- 操作 -->
        <el-table-column v-if="filterForm.tab === 'friends'" label="操作" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="DetailRow(scope.row)">
              查看
            </el-button>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead[filterForm.tab]">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :minWidth="th.minWidth || ''" :sortable="th.sortable" :key="index">
            <template slot-scope="scope">
              <!-- 头像 -->
              <el-image class="tabel-avatar" v-if="th.prop === 'avatar'"
                :src="fixImageUrl(scope.row[th.prop])" fit="cover">
              </el-image>
              <!-- 会员收益 -->
              <span v-else-if="['money_incomes','integral_incomes'].includes(th.prop)">+
                {{ scope.row[th.prop] | placeholder }}</span>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
  </div>
</template>

<script>
import listFilter from "../../components/Member/InvitationRevenueFilter";
import Pagination from "@/base/components/Default/Pagination";
import { InviteRevenue, InviteFriends } from "../../api/member/member-detail";
export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: {
        revenue: [
          // { label: "ID", prop: "user_id", minWidth: 70 },
          {
            label: "本月收益（元）",
            prop: "month_money",
            minWidth: 150,
            sortable: true,
          },
          {
            label: "累计收益（元）",
            prop: "total_money",
            minWidth: 150,
            sortable: true,
          },
          {
            label: "直邀好友收益（元）",
            prop: "first_money",
            minWidth: 180,
            sortable: true,
          },
          {
            label: "扩散好友收益（元）",
            prop: "second_money",
            minWidth: 180,
            sortable: true,
          },
          {
            label: "本月收益（积分）",
            prop: "month_integral",
            minWidth: 160,
            sortable: true,
          },
          {
            label: "累计收益（积分）",
            prop: "total_integral",
            minWidth: 160,
            sortable: true,
          },
          {
            label: "直邀好友收益（积分）",
            prop: "first_integral",
            minWidth: 190,
            sortable: true,
          },
          {
            label: "扩散好友收益（积分）",
            prop: "second_integral",
            minWidth: 190,
            sortable: true,
          },
          {
            label: "统计时间",
            prop: "time",
            minWidth: 160,
            sortable: true,
          },
        ],
        friends: [
          { label: "ID", prop: "cyc_user_id", minWidth: 80 },
          { label: "头像", prop: "avatar", minWidth: 100 },
          { label: "会员名称", prop: "nickname", minWidth: 110 },
          { label: "会内职务", prop: "title_name", minWidth: 150 },
          { label: "性别", prop: "gender" },
          { label: "籍贯", prop: "native_place", minWidth: 100 },
          { label: "会费状态", prop: "pay_status_text", minWidth: 90 },
          { label: "邀请类型", prop: "type_text", minWidth: 100 },
          {
            label: "收益（元）",
            prop: "money_incomes",
            minWidth: 115,
            sortable: true,
          },
          {
            label: "收益（积分）",
            prop: "integral_incomes",
            minWidth: 130,
            sortable: true,
          },
          {
            label: "入会时间",
            prop: "join_time",
            minWidth: 160,
            sortable: true,
          },
        ],
      },
      tabArr: [
        { label: "邀请列表", name: "friends" },
        { label: "收益列表", name: "revenue" },
      ],
      //筛选对象
      filterForm: {
        id: this.data?this.$deCode(this.data).id:this.$route.params.user_id,
        start_time: -1,
        end_time: -1,
        position_id: -1, // 职务id
        organization_id: -1, // 申请架构id
        gender: -1, // 性别
        invite_type: -1, // 邀请类型
        native_place: "", //籍贯
        pay_status: -1, // 会费状态
        keyword: "", //关键词
        page_size: 15, //每页多少条数据
        positionIds: "",
        tab: "friends",
      },
      //筛选缓存
      filters: {
        friends: null,
        revenue: null,
      },
      tbody: [],
      pageData: {},
    };
  },
  methods: {
    // 获取列表数据
    getInviteRevenue(pageData) {
      this.loading = true;
      this.tbody = [];
      if (pageData.tab == "friends") {
        InviteFriends(pageData)
          .then((res) => {
            const { data } = res;
            this.tbody = data.data;
            this.pageData = data;
            // this.handleMemberList();
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        InviteRevenue(pageData)
          .then((res) => {
            const { data } = res;
            this.tbody = data.data;
            this.pageData = data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 处理会员列表数据
    // handleMemberList() {
    //   let arr = []; // 用于存储表格合并所需数据
    //   let r = 0;
    //   let tbody = this.tbody;
    //   let table = []; // 用于存储会员列表处理后数据
    //   tbody.map((item, i, data) => {
    //     if (arr.length) {
    //       r = arr[arr.length - 1].row + data[i - 1].userPosition.length;
    //     }
    //     arr.push({
    //       row: r,
    //       index: item.userPosition.length,
    //     });
    //     if (item.userPosition && item.userPosition.length) {
    //       item.userPosition.map((userItem) => {
    //         table.push({ ...item, ...userItem });
    //       });
    //     }
    //   });
    //   console.log(arr, table);
    //   this.spanArr = arr;
    //   this.tbody = table;
    // },
    // 表格合并操作
    // arraySpanMethod(e) {
    //   if (
    //     !["title_name", "pay_status_text", "join_time"].includes(
    //       e.column.property
    //     )
    //   ) {
    //     let obj = [0, 0];
    //     this.spanArr.some((item) => {
    //       if (e.rowIndex === item.row) {
    //         obj = [item.index, 1];
    //       }
    //     });
    //     return obj;
    //   }
    // },
    // 查看会员
    DetailRow(row) {
      const data = {};
      data.id = row.cyc_user_id;
      data.type = "view";
      this.$router.push({
        name: this.$route.params.user_id? "UnitViewMember":"ViewMember",
        params: { data: this.$enCode(data) },
      });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getInviteRevenue(this.filterForm);
    },
    // 切换tab栏
    handleClick(e) {
      if (this.$filter && this.$filter.$layoutFilter) {
        this.$filter.$layoutFilter.reduction();
      }
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          id: this.$deCode(this.data).id,
          start_time: -1,
          end_time: -1,
          position_id: -1, // 职务id
          organization_id: -1, // 申请架构id
          gender: -1, // 性别
          native_place: "", //籍贯
          pay_status: -1, // 会费状态
          keyword: "", //关键词
          positionIds: "",
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getInviteRevenue(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getInviteRevenue({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getInviteRevenue({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getInviteRevenue(pageData);
    },
  },
  created() {
    this.getInviteRevenue(this.filterForm); //获取列表数据
  },
  components: { listFilter, Pagination },
};
</script>

<style lang="scss" scoped>
.body {
  min-height: calc(100vh - 270px);
  .tool-tip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .replay-status {
    display: flex;
    align-items: center;

    .replayed {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 5px;
      background: rgb(8, 243, 39);
    }

    .no-replay {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 5px;
      background: rgba(255, 203, 44, 1);
    }
  }
}
</style>
