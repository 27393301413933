import api from '@/base/utils/request'
import fetchFile from '@/base/utils/download'
import upload from '@/base/utils/upload4'

// 获取会员列表
export const memberList = (data) => {
  return api({
    url: '/admin/cyc/user/index',
    method: 'post',
    data,
    notCancel: true,
  })
}

//获取选择会员列表
export const SearchMemberList = (data) => {
  return api({
    url: '/admin/cyc/user/search',
    method: 'post',
    data,
    notCancel: true,
  })
}

// 获取所属架构/职务列表
export const positionLevel = (data) => {
  return api({
    url: '/admin/cyc/position/positionLevel',
    method: 'post',
    data,
  })
}

/**
 * 民族
 * @returns {AxiosPromise}
 */
export const nationality = () => {
  return api({
    url: '/admin/cyc/user/getNationalData',
    method: 'post',
  })
}

/**
 * 政治面貌
 * @returns {AxiosPromise}
 */
export const political = () => {
  return api({
    url: '/admin/cyc/user/getPoliticalStatus',
    method: 'post',
  })
}

// 退会
export const ExitOrganization = (data, notCancel) => {
  return api({
    url: '/admin/cyc/user/exitOrganization',
    method: 'post',
    data,
    notCancel,
  })
}

//会员列表导出
export const documentMemberList = (data) => {
  return fetchFile({
    url: '/admin/cyc/user/exportIndex',
    method: 'post',
    data,
    download: true,
  })
}

//会员excel模板下载
export const MemberListExample = () => {
  return fetchFile({
    url: '/admin/cyc/user_op/importMembersXlsx',
    method: 'post',
    download: true,
    data: {},
  })
}

//单位会员excel模板下载
export const ImportUnitsXlsx = () => {
  return fetchFile({
    url: '/admin/cyc/user_op/importUnitsXlsx',
    method: 'post',
    download: true,
    data: {},
  })
}

export const ImportUnits = (data) => {
  return api({
    url: '/admin/cyc/user_op/importUnits',
    method: 'post',
    data,
  })
}

export const importStudents = (data) => {
  return upload({
    url: '/admin/school/student/importStudents',
    typeArr: ['file'],
    data,
    timeout: 50000,
  })
}

export const getEntranceYears = (data) => {
  return api({
    url: '/admin/school/classes/entranceYears',
    method: 'post',
    data,
  })
}

export const getCampusAndPeriod = (data) => {
  return api({
    url: '/admin/school/settings/campusAndPeriod',
    method: 'post',
    data,
  })
}

// 会员/校友 不通过记录 列表
export const rejectList = (data) => {
  return api({
    url: '/admin/cyc/user/rejectList',
    method: 'post',
    data,
  })
}

// 会员/校友 不通过记录 导出
export const exportRejectList = (data) => {
  return fetchFile({
    url: '/admin/cyc/user/exportRejectList',
    method: 'post',
    data,
    download: true,
  })
}

// 修改入会时间
export const changeJoinTime = (data) => {
  return api({
    url: '/admin/cyc/userOp/changeJoinTime',
    method: 'post',
    data,
  })
}

// 批量导入会员
export const importByObject = (data) => {
  return api({
    url: '/admin/cyc/user_op/importMembers',
    method: 'post',
    data,
    notCancel: true,
  })
}

// 批量导入会员（旧）
export const importMembers = (data) => {
  return upload({
    url: '/admin/cyc/user_op/importMembers',
    typeArr: ['file'],
    data,
    timeout: 50000,
  })
}

export const changeApplyType = (ids) => {
  return api({
    url: '/admin/cyc/user_op/changeApplyType',
    method: 'post',
    data: {
      user_ids: ids,
    },
    notCancel: true,
  })
}
export const getLevelAndExperience = (data) => {
  return api({
    url: '/admin/cyc/user_op/getLevelAndExperience',
    method: 'post',
    data,
  })
}
export const changeExperience = (data) => {
  return api({
    url: '/admin/cyc/user_op/changeExperience',
    method: 'post',
    data,
  })
}

/**
 * 批量修改缴费状态（时间）
 */
export const changeFeeStatus = (data) => {
  return api({url:'/admin/cyc/user_op/payStatus', method: 'post', data, notCancel: true})
}

export const checkNonCycUser = (phone) => {
  return api({
    url: '/admin/cyc/user_op/checkNonCycUser',
    method: 'post',
    data: {phone}
  })
}

/**
 * 导出会员列表附件
 * @param data
 */
export const exportUserFiles = data => {
  return api({
    url: '/admin/cyc/user/exportIndexFiles',
    method: 'post',
    data,
  })
}